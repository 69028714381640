<template>
    <b-container fluid>
        <b-row>
            <b-col lg="12" sm="12">
                <iq-card>
                    <template v-slot:body>
                        <b-row class="details">
                            <b-col lg="12" sm="12">
                                <b-row>
                                    <b-col lg="12" sm="12">
                                        <b-row>
                                            <b-col md=12>
                                                <div style="font-size: 12px; background-color: rgb(51, 121, 130);"><h5 class="text-dark text-center"  style="color:#ffffff !important">{{$t('globalTrans.dealers_information')}}</h5></div>
                                                <b-table-simple striped bordered small hover>
                                                    <tbody>
                                                    <b-tr>
                                                        <b-td style="width: 50% !important;">{{ $t('globalTrans.dealer_name_title') }}</b-td>
                                                        <b-td style="width: 50% !important;">{{ (currentLocale == 'bn') ? data.applicant_address.applicant_name_bn : data.applicant_address.applicant_name }}</b-td>
                                                    </b-tr>
                                                    <b-tr>
                                                        <b-td>{{$t('globalTrans.org_name')}}</b-td>
                                                        <b-td  v-if="data.org_type === 2"><span v-if="data.applicant_org_name">{{ (currentLocale == 'bn') ? data.applicant_org_name_bn : data.applicant_org_name }}</span></b-td>
                                                        <b-td  v-if="data.org_type === 1"><span v-if="data.govt_org_id">{{organizationProfileListName(data.govt_org_id)}}</span></b-td>
                                                    </b-tr>
                                                    <b-tr>
                                                        <b-td>{{$t('globalTrans.registration_no_title')}}</b-td>
                                                        <b-td>{{ $n(data.organization_address.dealer_registration_number).replace(/\,/g,'') }}</b-td>
                                                    </b-tr>
                                                    <b-tr>
                                                        <b-td>{{$t('globalTrans.dealer_address_title')}}</b-td>
                                                        <b-td>{{ addreassMaker(data.applicant_address) }}</b-td>
                                                    </b-tr>
                                                    <b-tr>
                                                        <b-td>{{$t('globalTrans.mobile')}}</b-td>
                                                        <b-td>{{ data.applicant_address.a_mobile_phone_no | mobileNumber }}</b-td>
                                                    </b-tr>
                                                    </tbody>
                                                </b-table-simple>
                                                <div style="font-size: 12px; background-color: rgb(51, 121, 130);"><h5 class="text-dark text-center"  style="color:#ffffff !important">{{$t('globalTrans.seeds_information')}}</h5></div>
                                                <b-table-simple class="width-100" striped bordered small hover>
                                                    <tbody>
                                                    <b-tr>
                                                <b-td style="width: 20% !important;">{{$t('globalTrans.seed_name_title')}}</b-td>
                                                <b-td style="width: 50% !important;" v-if="data.seed_infos">
                                                    <span v-for="(info, index) in data.seed_infos" :key="index">
                                                        {{ currentLocale === 'en' ? info.seed_name : info.seed_name_bn }},
                                                    </span>
                                                </b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-td>{{$t('globalTrans.variety_name_title')}}</b-td>
                                                <b-td style="width: 50% !important;" v-if="data.seed_infos">
                                                    <span v-for="(info, index) in data.seed_infos" :key="index">
                                                        {{ currentLocale === 'en' ? info.seed_variety_name : info.seed_variety_name_bn }},
                                                    </span>
                                                </b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-td>{{$t('globalTrans.amount_seed_lot_title')}}</b-td>
                                                <b-td style="width: 50% !important;" v-if="data.seed_infos">
                                                    <span v-for="(info, index) in data.seed_infos" :key="index">
                                                        {{ currentLocale === 'en' ? info.seed_amount + seedUnit(info.unit_id) : $n(info.seed_amount)+seedUnit(info.unit_id)}},
                                                    </span>
                                                </b-td>
                                            </b-tr>
                                            <b-tr>
                                                <b-td>{{$t('globalTrans.lot_no_title')}}</b-td>
                                                <b-td style="width: 50% !important;" v-if="data.seed_infos">
                                                    <span v-for="(info, index) in data.seed_infos" :key="index">
                                                        {{ $n(info.lot_number, { useGrouping: false })}},
                                                    </span>
                                                </b-td>
                                            </b-tr>
                                                    <b-tr>
                                                        <b-td style="width: 50% !important;">{{$t('globalTrans.producer_name_title')}}</b-td>
                                                        <b-td style="width: 50% !important;">{{ (currentLocale === 'en') ? data.seed_source.seed_producer_name : data.seed_source.seed_producer_name_bn }}</b-td>
                                                    </b-tr>
                                                    <b-tr>
                                                        <b-td>{{$t('globalTrans.production_year_title')}}</b-td>
                                                        <b-td>{{ FiscalDateMaker(data.before_showing_planting.production_year) }}</b-td>
                                                    </b-tr>
                                                    </tbody>
                                                </b-table-simple>
                                            </b-col>
                                        </b-row>
                                        <div class="row">
                                            <div class="col-sm-3"></div>
                                            <div class="col text-right">
                                                <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.close') }}</b-button>
                                            </div>
                                        </div>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
    import { core } from '@/config/pluginInit'

    export default {
        props: ['item'],
        components: {
        },
        created () {
            this.data = this.item
        },
        mounted () {
            core.index()
        },
        data () {
            return {
                data: []
            }
        },
        computed: {
            currentLocale () {
                return this.$i18n.locale
            }
        },
        methods: {
            FiscalDateMaker (id) {
                const Year = this.$store.state.ExternalUserIrrigation.commonObj.fiscalYearList.find(item => item.text_en === id)
                if (Year) {
                    return this.$i18n.locale === 'en' ? Year.text_en : Year.text_bn
                }
            },
            seedUnit: function (id) {
                const seedUnit = this.$store.state.ExternalAgriResearch.agriResearchCommonObj.qualityUnitList.filter(item => item.value === id)
                if (seedUnit[0]) {
                    return this.$i18n.locale === 'en' ? seedUnit[0].text_en : seedUnit[0].text_bn
                }
               return ''
            },
            Checker (type) {
                return (type === 1) ? this.$t('request_testing_tag_without_field_certification_application.locally_produced') : this.$t('request_testing_tag_without_field_certification_application.imported')
            },
            addreassMaker (data) {
                return this.holding(data) + ', ' + data.a_village_name + ', ' + data.a_post_office_name + ', ' + this.unionName(data.a_union_id) + ', ' + this.upazilaName(data.a_upazilla_id) + ', ' + this.districtName(data.a_district_id)
                // data.a_village_name + ', ' + data.a_holding_number_en + ', ' + data.a_post_office_name
            },
            organizationProfileListName: function (id) {
                 const data = this.$store.state.ExternalUserIrrigation.commonObj.organizationProfileList.filter(item => item.value === id)
                if (data[0]) {
                    if (this.$i18n.locale === 'bn') {
                        return data[0].text_bn
                    } else {
                        return data[0].text_en
                    }
                } else {
                    return ''
                }
            },
            districtName (id) {
                const data = this.$store.state.ExternalUserIrrigation.commonObj.districtList.filter(item => item.value === id)
               if (data[0]) {
                   if (this.$i18n.locale === 'bn') {
                       return data[0].text_bn
                   } else {
                       return data[0].text_en
                   }
               } else {
                    return ''
                }
            },
            upazilaName (id) {
                const data = this.$store.state.ExternalUserIrrigation.commonObj.upazilaList.filter(item => item.value === id)
                if (data[0]) {
                    if (this.$i18n.locale === 'bn') {
                        return data[0].text_bn
                    } else {
                        return data[0].text_en
                    }
                } else {
                    return ''
                }
            },
            unionName (id) {
                const data = this.$store.state.ExternalUserIrrigation.commonObj.unionList.filter(item => item.value === id)
                if (data[0]) {
                    if (this.$i18n.locale === 'bn') {
                        return data[0].text_bn
                    } else {
                        return data[0].text_en
                    }
                } else {
                    return ''
                }
            },
            holding (data) {
                if (data) {
                    if (this.$i18n.locale === 'bn') {
                        return data.a_holding_number_bn
                    } else {
                        return data.a_holding_number
                    }
                } else {
                    return ''
                }
            }
        }
    }

</script>
<style lang="scss">
    .details {
        h5 {
            color: green !important;
            font-size: 20px !important;
        }
        .table-bordered td:nth-child(2n+1) {
            font-weight: 700;
        }
    }
    .hidden_header {
        display: none
    }
    [dir=ltr][mode=light] .card-body {
        flex: 1 1 auto;
        min-height: 1px;
        padding: 10px;
    }
</style>
