<template>
  <b-container fluid>
          <b-row>
              <b-col md="12">
                  <iq-card>
                      <template v-slot:headerTitle>
                          <div class="text-center">
                              <h4 class="card-title text-dark">{{ $t('certify_form.requesting_for_seed_testing_with_field_certification') + ' ' + $t('globalTrans.list') }}</h4>
                          </div>
                      </template>
                      <template v-slot:body>
                          <b-overlay :show="loadingState">
                              <b-row>
                                  <b-col md="12" class="table-responsive" v-if="datas">
                                      <b-table class="table" bordered hover :fields="columns" :items="datas" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                            <template v-slot:cell(index)="data">
                                                {{ $n(data.index + 1) }}
                                            </template>
                                            <template v-slot:cell(created_at)="data">
                                                {{data.item.created_at | dateFormat}}
                                            </template>
                                            <template v-slot:cell(seed_name)="data">
                                                <span v-for="(info, index) in data.item.seed_infos" :key="index">
                                                {{ $i18n.locale === 'en' ? info.seed_name : info.seed_name_bn}},
                                                </span>
                                            </template>
                                            <template v-slot:cell(seed_variety_name)="data">
                                                <span v-for="(info, index) in data.item.seed_infos" :key="index">
                                                {{ $i18n.locale === 'en' ? info.seed_variety_name : info.seed_variety_name_bn}},
                                                </span>
                                            </template>
                                            <template v-slot:cell(seed_class_name)="data">
                                                <span v-for="(info, index) in data.item.seed_infos" :key="index">
                                                {{ $i18n.locale === 'en' ? info.seed_class_name : info.seed_class_name_bn}},
                                                </span>
                                            </template>
                                          <template v-slot:cell(is_tested)="data">
                                                <span class="badge badge-dark" v-if="data.item.application_status === 10">{{ $i18n.locale === 'en' ? 'Lot Offered' : 'লট অফার' }}</span>
                                                <span class="badge text-dark badge-warning" v-if="data.item.application_status === 11">{{ $i18n.locale === 'en' ? 'Testing Payment Pending' : 'টেস্টিং পেমেন্ট পেন্ডিং' }}</span>
                                                <span class="badge badge-primary" v-if="data.item.application_status === 12">{{ $i18n.locale === 'en' ? 'Testing Payment Paid' : 'টেস্টিং পেমেন্ট পেইড' }}</span>
                                                <span class="badge badge-secondary" v-if="data.item.application_status === 13">{{ $i18n.locale === 'en' ? 'Collection Schedule Confirm' : 'কালেকশন শিডিউল কনফার্ম' }} <br> {{data.item.collection_date}}</span>
                                                <span class="badge badge-dark" v-if="data.item.application_status === 14">{{ $i18n.locale === 'en' ? 'Collected' : 'সংগৃহীত' }}</span>
                                                <span class="badge text-dark badge-info" v-if="data.item.application_status === 15">{{ $i18n.locale === 'en' ? 'Registered for Lab Testing' : 'ল্যাব পরীক্ষার জন্য নিবন্ধিত হয়েছে' }}</span>
                                                <span class="badge badge-dark" v-if="data.item.application_status === 16">{{ $i18n.locale === 'en' ? 'Result Submitted' : 'ফলাফল সাবমিট করা হয়েছে' }}</span>
                                                <span class="badge text-dark badge-success" v-if="data.item.application_status === 17  && data.item.tag_status === 1">{{ $i18n.locale === 'en' ? 'Result and Tag Approved' : 'ট্যাগ অনুমোদন' }}</span>
                                                <span class="badge badge-danger" v-if="data.item.application_status === 17  && data.item.tag_status === 2">{{ $i18n.locale === 'en' ? 'Result and Tag Rejected' : 'ট্যাগ বাতিল' }}</span>
                                                <span class="badge text-dark badge-warning" v-if="data.item.application_status === 18">{{ $i18n.locale === 'en' ? 'Lot Confirmed' : 'লট কনফার্মড' }}</span>
                                                <span class="badge badge-primary" v-if="data.item.application_status === 19">{{ $i18n.locale === 'en' ? 'Tag Payment Paid' : 'ট্যাগ পেমেন্ট' }}</span>
                                          </template>
                                          <template v-slot:cell(testing_status)="data">
                                              <div style="width: 100px">
                                                {{ data.item.testing_status === 'Without' ? ($i18n.locale === 'en' ? 'Without field certification' : 'প্রত্যয়ন বহির্ভূত') : ($i18n.locale === 'en' ? 'With field certification' : 'প্রত্যয়নের আওতাধীন') }}
                                              </div>
                                          </template>
                                          <template v-slot:cell(action)="data">
                                              <div style="width: 100px">
                                                  <b-button v-b-modal.modal-6 v-if="data.item.application_status >= 12" variant=" iq-bg-success mr-1" size="sm" title="Payment Recipt" @click="view(data.item)"><i class="ri-attachment-line m-1"></i></b-button>
                                                  <b-button v-b-modal.modal-5 v-if="data.item.application_status == 10"  variant=" iq-bg-success mr-1" size="sm" title="Payment" @click="view(data.item)"><i class="fas fa-money-bill-alt"></i></b-button>
                                                  <b-button v-b-modal.modal-4 variant=" iq-bg-success mr-1" size="sm" title="Details View" @click="payment(data.item)"><i class="ri-eye-line m-1"></i></b-button>
                                                  <b-button v-b-modal.modal-9 v-if="data.item.application_status >= 16" variant=" iq-bg-success mr-1" size="sm" title="Test Result Details" @click="view(data.item)"><i class="ri-file-mark-fill m-1"></i></b-button>
                                              </div>
                                          </template>
                                      </b-table>
                                      <b-pagination
                                          v-model="pagination.currentPage"
                                          :per-page="pagination.perPage"
                                          :total-rows="pagination.totalRows"
                                          @input="searchData"
                                      />
                                  </b-col>
                              </b-row>
                          </b-overlay>
                      </template>
                  </iq-card>
              </b-col>
          </b-row>
          <b-modal id="modal-4" size="xl" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
              <DetailsV @eventname="updateparent"  :item="itemDetails"/>
          </b-modal>
          <b-modal id="modal-5" size="xl" :title="$t('external_research.make_payment')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
              <PaymentV :item="itemDetails"/>
          </b-modal>
          <b-modal id="modal-6" size="md" :title="formTitle1" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
              <MoneyReceiptV :item="itemDetails"/>
          </b-modal>
          <b-modal id="modal-9" size="xl" :title="formTitle2" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
                <DetailsDV v-on:listenerChild="listenerChild" :item="itemDetails"/>
          </b-modal>
  </b-container>
</template>

<script>
import DetailsDV from '../test-result-and-tag-requisition/Details.vue'
import DetailsV from './Details.vue'
import PaymentV from './Payment.vue'
import MoneyReceiptV from './MoneyReceipt.vue'
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { testingTagRequestWithCetification } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import { core } from '@/config/pluginInit'
export default {
    components: {
        // eslint-disable-next-line vue/no-unused-components
        DetailsV, PaymentV, MoneyReceiptV, DetailsDV
    },
    mixins: [ModalBaseMasterList],
    data () {
        return {
            search: {
                name: ''
            },
            itemDetails: [],
            application: {},
            datas: [],
            info: {
                user_id: this.$store.state.Auth.authUser.id
            }
        }
    },
    computed: {
        formTitle () {
            return this.$t('certify_form.application_details')
        },
        formTitle1 () {
            return this.$t('certify_form.money_receipt_title')
        },
        formTitle2 () {
            return this.$t('external_research.test_result_details')
        },
        columns () {
            const labels = [
                { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
                { label: this.$t('globalTrans.application_date'), class: 'text-left' },
                { label: this.$t('globalTrans.dealer_name_title'), class: 'text-left' },
                { label: this.$t('globalTrans.seed_name_title'), class: 'text-left' },
                { label: this.$t('globalTrans.variety_name_title'), class: 'text-center' },
                { label: this.$t('globalTrans.class_name_title'), class: 'text-center' },
                { label: this.$t('certify_form.application_type'), class: 'text-center' },
                { label: this.$t('globalTrans.status'), class: 'text-center' },
                { label: this.$t('globalTrans.action'), class: 'text-center' }
            ]

            let keys = []

            if (this.$i18n.locale === 'bn') {
                keys = [
                    { key: 'index' },
                    { key: 'created_at' },
                    { key: 'applicant_address.applicant_name_bn' },
                    { key: 'seed_name' },
                    { key: 'seed_variety_name' },
                    { key: 'seed_class_name' },
                    { key: 'testing_status' },
                    { key: 'is_tested' },
                    { key: 'action' }
                ]
            } else {
                keys = [
                    { key: 'index' },
                    { key: 'created_at' },
                    { key: 'applicant_address.applicant_name' },
                    { key: 'seed_name' },
                    { key: 'seed_variety_name' },
                    { key: 'seed_class_name' },
                    { key: 'testing_status' },
                    { key: 'is_tested' },
                    { key: 'action' }
                ]
            }
            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        }
    },
    created () {
        this.paymentStatusUpdate()
    },
    mounted () {
        core.index()
        this.loadData()
    },
    methods: {
        updateparent () {
            this.loadData()
        },
    getDivision (divisionId) {
        return this.$store.state.ExternalUserIrrigation.commonObj.divisionList.find(item => item.division_id === divisionId).text_en
    },
    getUpazila (upazilaId) {
        return this.$store.state.ExternalUserIrrigation.commonObj.upazilaList.find(item => item.upazila_id === upazilaId).text_en
    },
    getUnion (unionId) {
        return this.$store.state.ExternalUserIrrigation.commonObj.unionList.find(item => item.union_id === unionId).text_en
    },
    getDistrict (districtId) {
        return this.$store.state.ExternalUserIrrigation.commonObj.districtList.find(item => item.district_id === districtId).text_en
    },
        async searchData () {
            this.loadData()
        },
        updateValue () {
            this.listView = true
            this.loadData()
        },
        view (item) {
            this.itemDetails = item
        },
        payment (item) {
            this.itemDetails = item
        },
        loadData () {
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            const params = Object.assign({}, this.info, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            RestApi.getData(agriResearchServiceBaseUrl, testingTagRequestWithCetification, params).then(response => {
                if (response.success) {
                    this.datas = response.data.data
                    this.paginationData(response.data)
                }
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            })
        },
        CheckRegister (type) {
            return (type === 1) ? this.$t('request_testing_tag_without_field_certification_application.registered_status') : this.$t('request_testing_tag_without_field_certification_application.un-registered_status')
        },
        async paymentStatusUpdate () {
            if (this.$route.params.status && this.$route.query.transId) {
                const status = this.$route.params.status
                const tranId = this.$route.query.transId
                this.$bvModal.show('modal-processing')
                await RestApi.getData(agriResearchServiceBaseUrl, '/application/payment/' + status, { transId: tranId }).then(response => {
                this.$bvModal.hide('modal-processing')
                if (response.success) {
                    this.$toast.success({
                    title: 'Success',
                    message: response.message,
                    color: '#D6E09B'
                    })
                    this.loadData()
                } else {
                    this.$toast.error({
                    title: 'Error',
                    message: response.message
                    })
                }
                })
                this.$router.push('/research-farmer/request-testing-tag/request-testing-tag-with-field-certification')
            }
        }
    }
}
</script>
<style scoped>
    .form-control {
        padding: 0.375rem 0.75rem
    }
    .custom-card-design{
        background: #005B5B !important;
    }
    .custom-card-design-title{
        color: white !important;
    }
</style>
